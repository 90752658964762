<template>
 <div>
    <v-row no-gutters class="pa-2">
      <v-col cols='12'>
         
        </v-col>
        <v-col cols='12'>
          <!-- <v-toolbar dense elevation="1">
              Cashouts
              <v-spacer/>
              <v-btn fab small color="info" @click="confirm = true"><v-icon center>mdi-plus</v-icon></v-btn>
            </v-toolbar> -->
          <div class="pa-2">
           
          <table class="print_table md-font mt-4" >
                      <tr>
                          <th class="text-left">Draw</th>
                          <th class="text-left">Summary</th>
                          <th class="text-right">Amount</th>
                          <th class="text-right">Status</th>
                      </tr>
                      <tr>
                          <td>YYYY-MM-DD 11PM </td>
                          <td>
                              Sales: 10,00
                              <br />Co
                            </td>
                          <td></td>
                          <td></td>
                      </tr>
                      
                  </table>
                </div>    
        </v-col>
    </v-row>
    <va-cash-out :show="confirm"  @DialogEvent="cEvent"/>
</div>
</template>

<script>
import {
  mapMutations
} from 'vuex'
export default {
    data: () => ({
      transactions:[],
        text: '',
        gcash: '',
        gcash_name: '',
        action:"request",
        confirmMsg:"",
        confirm: false,
        pending: {id:0},
        sendbtn:"SEND",
         interval: {},
         loading: false,
         value:0,
         auth: false,
    }),
  mounted(){
      this.setLoggedIn(true)
       this.setDrawer(false)
        if (this.user == null) {
          this.$router.push('/')
        }else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
          //this.getCashout()
        }
    },
  computed:{
    user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
     
  },
  methods: {
    ...mapMutations(['setDrawer']),
    cEvent() {
      this.confirm = false
    }
  }
}
</script>
